import React, { createContext, useState, useEffect } from "react";
import { api } from "../utils/fetch";

interface IOpenContext {
  open: "unknown" | "true" | "false";
  message?: string;
}

export const OpenContext = createContext<IOpenContext>({ open: "unknown" });

export default function OpenContextProvider({
  children,
}: React.PropsWithChildren) {
  const [open, setOpen] = useState<IOpenContext>({ open: "unknown" });

  useEffect(() => {
    api("/api/settings").then(({ isOpen, message }) =>
      setOpen({
        open: isOpen ? "true" : "false",
        message,
      })
    );
  }, []);

  // const ClosedPage = (
  //   <div
  //     style={{
  //       height: "100%",
  //       width: "100%",
  //       display: "flex",
  //       flexDirection: "column",
  //       backgroundColor: "white",
  //     }}
  //   >
  //     <span
  //       style={{
  //         fontSize: "36px",
  //         textAlign: "center",
  //         marginTop: "25%",
  //       }}
  //     >
  //       {" "}
  //       {open.message}{" "}
  //     </span>
  //     <div style={{ height: "60%", width: "100%", marginTop: "5%" }}></div>
  //     <img src="/static/img/logo.png" alt="" style={{ height: "100%" }} />
  //   </div>
  // );

  return <OpenContext.Provider value={open}>{children}</OpenContext.Provider>;
}
