import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { api } from "../../utils/fetch";
import { Add } from "@mui/icons-material";
import { TransitionProps, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { IOrder } from "../orders/Orders";
import Order from "../order/order";
import DeliveryDiningTwoToneIcon from "@mui/icons-material/DeliveryDiningTwoTone";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import dayjs from "dayjs";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { AuthContext } from "../../context/Auth";
import { OpenContext } from "../../context/Open";

function Cart() {
  const { user } = useContext(AuthContext);

  const {
    data: orders,
    refetch,
    isLoading,
  } = useQuery<IOrder[]>({
    queryKey: ["orders"],
    queryFn: () => api("/api/domand/user"),
  });
  const navigate = useNavigate();
  const { open: isOpen, message } = useContext(OpenContext);
  const [pickuptime, setpickuptime] = useState<{ time: undefined | Date }>({
    time: undefined,
  });
  interface Ipickupopt {
    emporter?: boolean;
    latitude: string;
    longitude: string;
    location: string;
    pickup?: Date | string;
    user_phone?: string;
  }
  const [pickupOpt, setpickupOpt] = useState<Ipickupopt>({
    emporter: undefined,
    latitude: "",
    longitude: "",
    location: "",
    pickup: undefined,
    user_phone: undefined,
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setpickupOpt({ ...pickupOpt, user_phone: user?.phone || undefined });
  }, [user?.phone]);

  const handleClose = () => {
    setOpen(false);
  };
  const phoneregex = /^(05|06|07)\d{8}$/;
  const currentOrder = orders?.find(({ state }) => state === "draft");
  if (isLoading) return <CircularProgress />;
  if (!currentOrder) return <p>Aucune commande en cours</p>;
  else {
    const {
      id,
      price: { totale, order },
      create_time,
    } = currentOrder;
    return (
      <div
        className="flex flex-col justify-between"
        style={{ height: "calc(100vh - 140px)" }}
      >
        <div style={{ height: "80%" }}>
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "90%",
              overflow: "auto",
              gap: "10px",
            }}
          >
            <ListItem>
              <ListItemText
                primary={`Commande n°${id} - ${totale} DA`}
                secondary={new Date(create_time).toLocaleString("fr-FR")}
              />
            </ListItem>
            <div className="w-screen">
              <Order order={order} id={id} refetch={refetch} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Button variant="contained" onClick={() => navigate("/")}>
              Plus <Add />
            </Button>
          </div>
        </div>

        <div>
          {" "}
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <div>
              <div
                style={{
                  gap: "20px",
                  height: "calc(100vh - 140px)",
                  display: "flex",
                }}
                className="flex  items-center flex-col overflow-y-scroll"
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginTop: "30px",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #888",
                      borderRadius: "16px",
                      height: "60px",
                      width: "95%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "center",
                        fontFamily: "roboto",
                        fontSize: "22px",
                      }}
                    >
                      Ajoutez votre numero de telephone
                    </span>
                  </div>

                  <TextField
                    placeholder="Numero de Telephone"
                    value={pickupOpt.user_phone}
                    sx={{
                      width: "300px",
                      border: "1px solid #1976d2 ",
                    }}
                    onChange={(e) => {
                      setpickupOpt({
                        ...pickupOpt,
                        user_phone: e.target.value.toString(),
                      });
                    }}
                  />
                  <Button
                    disabled={!phoneregex.test(pickupOpt.user_phone!)}
                    sx={{
                      height: "60px",
                      fontSize: "18px",
                      fontWeight: "600",
                      fontFamily: "roboto",
                      letterSpacing: "2px",
                      width: "220px",
                      border:
                        pickupOpt.emporter && pickupOpt.emporter
                          ? "2px solid green"
                          : "1px solid #888",
                      borderRadius: "16px",
                      "&:hover": { border: "2px solid green" },
                    }}
                    variant="outlined"
                    endIcon={
                      <DirectionsWalkIcon
                        sx={{
                          color: "green",
                          fontSize: "30px",
                          marginLeft: "20px",
                        }}
                      />
                    }
                    onClick={() => {
                      setpickupOpt({
                        ...pickupOpt,
                        emporter: true,
                        latitude: "",
                        longitude: "",
                        location: "",
                      });
                    }}
                  >
                    Récupérer{" "}
                  </Button>
                  <Button
                    disabled={!phoneregex.test(pickupOpt.user_phone!)}
                    sx={{
                      height: "60px",
                      fontSize: "18px",
                      fontWeight: "600",
                      fontFamily: "roboto",
                      letterSpacing: "2px",
                      width: "220px",
                      border:
                        pickupOpt.emporter && !pickupOpt.emporter
                          ? "2px solid green"
                          : "1px solid #888",
                      borderRadius: "16px",
                      "&:hover": { border: "2px solid green" },
                    }}
                    onClick={() => {
                      setpickupOpt({ ...pickupOpt, emporter: false });
                    }}
                    variant="outlined"
                    endIcon={
                      <DeliveryDiningTwoToneIcon
                        sx={{ color: "green", fontSize: "30px" }}
                      />
                    }
                  >
                    Livrer
                  </Button>
                </div>

                {pickupOpt.emporter === false && (
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      padding: "0px 8px",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {" "}
                    <Button
                      endIcon={
                        <AddLocationIcon
                          sx={{ color: "green", fontSize: "30px" }}
                        />
                      }
                      sx={{
                        height: "60px",
                        fontSize: "18px",
                        fontWeight: "600",
                        fontFamily: "roboto",
                        letterSpacing: "2px",
                        backgroundColor: pickupOpt.latitude
                          ? "#019934"
                          : "white",
                        "&:hover": {
                          backgroundColor: pickupOpt.latitude
                            ? "#019934"
                            : "white",
                        },
                      }}
                      variant={pickupOpt.latitude ? "contained" : "outlined"}
                      onClick={() => {
                        navigator.geolocation.getCurrentPosition((position) => {
                          const latitude = position.coords.latitude;
                          const longitude = position.coords.longitude;
                          setpickupOpt({
                            ...pickupOpt,
                            latitude: `${latitude}`,
                            longitude: `${longitude}`,
                            location: "",
                            // pickup: `2023-11-6 8:30:00.500`,
                          });
                        });
                      }}
                    >
                      Livrai a m'a position
                    </Button>{" "}
                    <span>OU</span>
                    <TextField
                      placeholder="Indiquer l'adresse de livraison"
                      sx={{
                        width: "250px",
                        marginLeft: "8px",
                        border: "#1976d2",
                        borderRadius: "16px",
                      }}
                      onChange={(e) => {
                        setpickupOpt({
                          ...pickupOpt,
                          location: e.target.value,
                          latitude: "",
                          longitude: "",
                        });
                      }}
                    />
                  </div>
                )}
              </div>

              {pickupOpt.emporter !== undefined && (
                <Button
                  disabled={
                    pickupOpt.emporter === undefined ||
                    (pickupOpt.emporter === false &&
                      (pickupOpt.latitude === "" ||
                        pickupOpt.longitude === "") &&
                      pickupOpt.location === "") ||
                    pickupOpt.user_phone === undefined
                  }
                  variant="contained"
                  color="success"
                  sx={{
                    height: "60px",
                    fontSize: "20px",
                    marginTop: "10px",
                    width: "200px",
                    justifySelf: "center",
                    marginLeft: "calc(50% - 100px)",
                    // marginBottom: "20px",
                  }}
                  onClick={() => {
                    console.log("pickup time ", pickuptime);
                    console.log(pickupOpt);
                    setpickupOpt({
                      ...pickupOpt,
                      pickup: pickuptime?.time?.toISOString(),
                    });
                    api("/api/domand/valideOrder", {
                      method: "PUT",
                      body: JSON.stringify(pickupOpt),
                    }).then(() => {
                      enqueueSnackbar("Commande validée", {
                        variant: "success",
                      });
                      navigate("/orders");
                    });
                    handleClose();
                  }}
                >
                  Confirmer
                </Button>
              )}
            </div>
          </Dialog>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",

            position: "fixed",
            bottom: "calc(3% + 65px)",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", color: "red", marginBottom: "10px" }}
          >
            {message}
          </Typography>
          <Button
            disabled={isOpen === "false"}
            variant="contained"
            sx={{ backgroundColor: "green", width: "200px" }}
            onClick={() => {
              handleClickOpen();
            }}
          >
            Confirmer
          </Button>
        </div>
      </div>
    );
  }
}

export default Cart;
