import React, { useReducer } from "react";
import { IIngredient } from "../../pages/Home";
import {
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import reducer, {
  initialState,
} from "../../components/CustomiseSandwich.jsx/reducer";

interface IIngredientsProps {
  ingredients: IIngredient[];
}

export default function Ingredients({ ingredients }: IIngredientsProps) {
  const [{ chosenIngredients }] = useReducer(reducer, initialState);
  return (
    <>
      {ingredients.map(({ id, name, unity, price, composition, order_ing_amount }) => (
        <ListItem key={id} sx={{ backgroundColor: "white" }}>
          <ListItemText
            sx={{ paddingLeft: 2 }}
            primary={`${name} * ${order_ing_amount}`}
            secondary={`Lipides: ${composition.lipide}, Energie: ${composition.energie}, Glucides: ${composition.glucide}, Protéines: ${composition["protéines"]}`}
          />
          <ListItemSecondaryAction>
            <ListItemText
              primary={`${price*order_ing_amount} DA`}
              secondary={`${unity}`}
              sx={{
                "& .MuiListItemText-secondary": {
                  textAlign: "right",
                },
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </>
  );
}
