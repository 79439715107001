import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { api } from "../../utils/fetch";
import React, { useState } from "react";
import { Delete, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { IOrder } from "../orders/Orders";
import Ingredients from "./Ingredients";

interface IOrderProps {
  refetch: () => void;
  order: IOrder["price"]["order"];
  id: number;
  deletable?: boolean;
}

export default function Order({
  order,
  refetch,
  id,
  deletable = true,
}: IOrderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [openOrder, setOpenOrder] = useState<number | null>(null);
  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <List sx={{ marginTop: 0 }}>
        {order.map(
          ({ orderId, price, plate: { plateName, ing, plateId } }, index) => (
            <>
              <ListItem
                sx={{
                  backgroundColor: "white",
                  border: "1px solid #888",
                  marginBottom: "5px",
                }}
              >
                <ListItemText
                  primary={plateName}
                  secondary={`${price.toLocaleString('fr-FR')} DA`}
                  sx={{
                    paddingLeft: 1,
                  }}
                />
                <ListItemSecondaryAction>
                  {deletable && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        api(`/api/order/${orderId}`, {
                          method: "DELETE",
                        }).then(() => {
                          enqueueSnackbar("Plat supprimé", {
                            variant: "success",
                          });
                          refetch();
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="delete"
                    onClick={() =>
                      setOpenOrder(index === openOrder ? null : index)
                    }
                    sx={{ paddingRight: 1 }}
                  >
                    {openOrder === index ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={openOrder === index} timeout="auto" unmountOnExit>
                <List>
                  <Ingredients ingredients={ing} />
                </List>
              </Collapse>
            </>
          )
        )}
      </List>
    </Collapse>
  );
}
