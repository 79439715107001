import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { api } from "../utils/fetch";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

export interface IDish {
  id: number;
  name: string;
  photo: string;
  price: number;
  plate_has_ing: IIngredient[];
  plate_has_ing_cat: IIngredientCategory[];
}

export interface ICategory {
  id: number;
  name: string;
  list: IDish[];
}

export interface IIngredient {
  id: number;
  category: string;
  name: string;
  unity: string;
  price: number;
  quantity: number;
  order_ing_amount: number;
  composition: {
    lipide: string;
    energie: string;
    glucide: string;
    protéines: string;
  };
  available: boolean;
  image: string;
}

export interface IIngredientCategory {
  category: string;
  type: "obligatory" | "optional";
  multi: boolean;
  plate_id: number;
  ing_cat_id: number;
  ing: IIngredient[];
}

export default function Home() {
  const { id } = useParams();
  const { data: categories, isLoading } = useQuery<ICategory[]>({
    queryKey: ["articles"],
    queryFn: () => api("/api/plate"),
  });

  const [chosenCategory, setChosenCategory] = useState<number | null>(null);

  const navigate = useNavigate();

  return (
    <List
      sx={{
        height: "calc(100vh - 140px)",
        overflowY: "scroll",
      }}
    >
      {isLoading && (
        <div style={{ margin: 8 }}>
          {[...Array(10)].map((_, i) => (
            <Skeleton height={67} key={`skeleton-${i}`} />
          ))}
        </div>
      )}
      {!isLoading && (
        <div
          onClick={() =>
            navigate(
              `/dish/${
                id === "1"
                  ? "1000"
                  : id === "2"
                  ? "1001"
                  : id === "3"
                  ? "1002"
                  : "1003"
              }`
            )
          }
          style={{
            backgroundColor: "white",
            marginBottom: "20px",
            padding: "16px 16px",
            display: "flex",

            justifyContent: "center",
            border: "2px solid #2cd665",
            height: "130px",
            flexDirection: "column",
            alignItems: "center",
            width: "90%",
            marginLeft: "5%",
            borderRadius: "40px",
            cursor: "pointer",
          }}
        >
          {" "}
          <span
            style={{
              fontSize: "25px",
              fontWeight: "600",
            }}
          >
            {id === "1"
              ? "Plat sur mesure"
              : id === "2"
              ? "Sandwich sur mesure"
              : id === "3"
              ? "Boisson sur mesure "
              : "Dessert"}
          </span>
          <span style={{ width: "100%", textAlign: "center" }}>
            {" "}
            Composez votre{" "}
            {id === "1"
              ? "Plat "
              : id === "2"
              ? "Sandwich "
              : id === "3"
              ? "Boisson"
              : "Dessert"}{" "}
            à partir d'ingrédients frais et de qualité
          </span>
        </div>
      )}
      {!isLoading &&
        categories
          ?.filter((e) => e.id === parseInt(id!))
          .map(({ list }) => (
            <>
              <List sx={{ backgroundColor: "#f2f2f2" }}>
                {list.map(({ id, name, photo, price, plate_has_ing }) => (
                  <div
                    key={`dish${id}`}
                    onClick={() => navigate(`/dish/${id}`)}
                    style={{
                      backgroundColor: "white",
                      marginBottom: "20px",
                      padding: "16px 16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid #b7b7b7",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={`/upload/${photo}`}
                      alt={name}
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: 8,
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",

                        gap: "10px",
                      }}
                    >
                      {" "}
                      <span style={{ fontSize: "20px", fontWeight: "600" }}>
                        {name}
                      </span>
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 150,
                        }}
                      >
                        {plate_has_ing.map(({ name }) => name).join(", ")}
                      </span>
                    </div>
                    {/* <ListItemText
                      sx={{ marginLeft: "16px" }}
                      primary={name}
                      secondary={plate_has_ing
                        .map(({ name }) => name)
                        .join(", ")}
                    /> */}

                    <span style={{ fontSize: "17px", fontWeight: "600" }}>
                      {" "}
                      {price} DA{" "}
                    </span>
                  </div>
                ))}
              </List>
            </>
          ))}
    </List>
  );
}
