import React, { useEffect, useReducer, useRef } from "react";
import "./costomiseSandwich.css";
import { Button, IconButton, Step, Stepper } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import reducer, { initialState } from "./reducer";
import { IDish } from "../../pages/Home";
import { enqueueSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
function CustomiseSandwich() {
  const { id } = useParams();

  const { data } = useQuery<IDish>({
    queryFn: () =>
      fetch(`/api/plate/${id}`)
        .then((res) => res.json())
        .then((res) => {
          return res;
        }),
    queryKey: ["dish by id", id],
  });
  const [{ chosenIngredients, activeCategory, selectedIngridient }, dispatch] =
    useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({
      type: "setIngredientQuantities",
      payload: data?.plate_has_ing.reduce(
        (acc, { id, quantity, available }) => ({
          ...acc,
          [id]: available ? quantity : 0,
        }),
        {}
      ),
    });
  }, [data]);
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollRef?.current?.scroll({
      left:
        activeCategory === (data?.plate_has_ing_cat?.length || 1) - 1
          ? scrollRef?.current?.clientWidth
          : (scrollRef?.current?.clientWidth * activeCategory) /
              (data?.plate_has_ing_cat?.length || 1) +
            (activeCategory ? 36 : 0),
    });
  }, [activeCategory, data?.plate_has_ing_cat?.length]);

  const activeCategoryObject = data?.plate_has_ing_cat?.[activeCategory];
  const navigate = useNavigate();

  const allIngredients = data?.plate_has_ing_cat.reduce<
    IDish["plate_has_ing_cat"][number]["ing"][number][]
  >((acc, { ing }) => [...acc, ...ing], []);

  const editedIngredients = Object.keys(chosenIngredients).reduce<
    Record<number, number>
  >(
    (acc, key) => ({
      ...acc,
      [key]:
        (chosenIngredients[key] || 0) -
        (data?.plate_has_ing.find(({ id }) => id! === parseInt(key, 10))
          ?.quantity || 0),
    }),
    {}
  );

  const newPrice = Object.keys(editedIngredients).reduce(
    (acc, cur) =>
      acc +
      (allIngredients?.find(({ id }) => id === parseInt(cur))?.price || 0) *
        (editedIngredients[parseInt(cur)] || 0),
    data?.price || 0
  );

  return (
    <div className="main">
      <div className="head">
        <div className="sandwich_name">
          {" "}
          <span
            style={{
              width: "fit-content",
              height: "fit-content",
              fontSize: "25px",
              marginLeft: "50px",
            }}
          >
            {data?.name}
          </span>
          <span
            style={{
              width: "fit-content",
              height: "fit-content",
              fontSize: "25px",
              marginLeft: "30%",
            }}
          >
            {" "}
            {newPrice} DA{" "}
          </span>
        </div>
        <div>
          {data && (
            <div className="stepper" id="stepper-container" ref={scrollRef}>
              <Stepper sx={{ marginTop: "20px" }}>
                {data?.plate_has_ing_cat.map((x, index) => (
                  <Step id={`step-${activeCategory}`}>
                    <Button
                      sx={{
                        marginRight: "20px",
                        color: activeCategory === index ? "white" : "black",
                        borderRadius: "20px",
                        backgroundColor:
                          activeCategory === index ? "#019934" : "white",
                        "&:hover": {
                          color: activeCategory === index ? "white" : "black",
                          backgroundColor:
                            activeCategory === index ? "green" : "white",
                          boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)`,
                        },
                        "&.Mui-focusVisible": {
                          boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)`,
                        },
                        "&.Mui-active": {
                          boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)`,
                        },
                      }}
                      variant="contained"
                      disableRipple
                    >
                      {x.category}{" "}
                    </Button>
                  </Step>
                ))}
              </Stepper>
            </div>
          )}
        </div>
      </div>
      <div
        className="custom_ingredient"
        style={{
          overflowY: "scroll",
          height: "calc(100vh - 400px)",
          padding: "16px 0px",
          backgroundColor: "#f2f2f2",
        }}
      >
        {activeCategoryObject?.ing.map((x) => {
          const chosenIngredient =
            chosenIngredients[
              Object.keys(chosenIngredients).find(
                (id) => parseInt(id) === x.id
              ) as keyof typeof chosenIngredients
            ];
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid #bebebe",
                margin: 8,
                borderRadius: 8,
                backgroundColor: "white",
                // borderLeft: Object.keys(chosenIngredients).find(
                //   (z) => parseInt(z) === x.id
                // )
                //   ? "1px solid green"
                //   : "1px solid #bebebe",
                boxShadow:
                  Object.keys(chosenIngredients).find(
                    (z) => parseInt(z) === x.id
                  ) &&
                  chosenIngredients[
                    `${x.id}` as keyof typeof chosenIngredients
                  ] !== undefined &&
                  chosenIngredients[
                    `${x.id}` as keyof typeof chosenIngredients
                  ] !== 0
                    ? "-6px 0px 1px rgba(1,153,52,0.7)"
                    : "",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "16px",

                  padding: "8px 4px",
                  // justifyContent: "space-between",
                  height: "120px",
                }}
                className="ingredient"
              >
                <div
                  style={{
                    height: "80px",
                    width: "80px",

                    marginTop: "12px",
                  }}
                >
                  <img
                    src={`/upload/${x.image}`}
                    alt=""
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "8px",
                      maxWidth: "80px",
                      minWidth: "80px",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexGrow: "1",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      height: "100%",
                      justifyContent: "center",
                      gap: "6px",
                      width: "fit-content",
                      marginLeft: "12px",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        height: "1lh",
                        position: "absolute",
                        top: 0,
                      }}
                    >
                      {x.name}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "calc(1lh + 4px)",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#777",
                        }}
                      >
                        {x.unity}
                      </span>

                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#777",
                        }}
                      >
                        {(
                          parseFloat(x.composition?.energie) *
                          0.01 *
                          parseFloat(x.unity)
                        ).toFixed(2)}{" "}
                        kcal
                      </span>
                    </div>
                    <span
                      style={{
                        fontSize: "14px",
                        textDecoration: "underline",
                        color: "green",
                      }}
                      onClick={() => {
                        dispatch({
                          type: "selectIngridient",
                          payload:
                            selectedIngridient === x.id ? undefined : x.id,
                        });
                      }}
                    >
                      Détail
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {x.available ? (
                        <div
                          style={{
                            backgroundColor: "white",

                            marginRight: 8,
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "row",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <IconButton
                            disabled={(chosenIngredient || 0) === 0}
                            onClick={() => {
                              dispatch({
                                type: "setIngredientQuantity",
                                payload: {
                                  id: x.id,
                                  quantity: (chosenIngredient || 0) - 1,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: "white",
                              fontSize: "1.25em",
                              padding: 0,
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <span
                            style={{
                              margin: "0px 4px",
                              backgroundColor: "white",
                              padding: "0px 4px",
                              borderRadius: "2px",
                            }}
                          >
                            {chosenIngredient || 0}
                          </span>
                          <IconButton
                            style={{
                              backgroundColor: "white",
                              fontSize: "1.25em",
                              padding: 0,
                              marginRight: "4px",
                            }}
                            onClick={() => {
                              dispatch(
                                activeCategoryObject.multi
                                  ? {
                                      type: "setIngredientQuantity",
                                      payload: {
                                        id: x.id,
                                        quantity: (chosenIngredient || 0) + 1,
                                      },
                                    }
                                  : {
                                      type: "setSingleIngredient",
                                      payload: {
                                        id: x.id,
                                        init: activeCategoryObject.ing
                                          .map(({ id }) => id)
                                          .filter((id) => id !== x.id),
                                      },
                                    }
                              );
                              console.log(chosenIngredients);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          {x.price * (chosenIngredients[x.id] || 0)} DA
                        </div>
                      ) : (
                        <span
                          style={{
                            fontSize: "16px",
                            color: "black",
                            fontWeight: "600",
                            marginRight: "8px",
                            fontStyle: "italic",
                          }}
                        >
                          {" "}
                          Indisponible
                        </span>
                      )}

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {selectedIngridient === x.id && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <table
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: "10px",
                    }}
                  >
                    <tbody>
                      {Object.keys(x.composition).map((cle) => (
                        <tr>
                          <td key={cle}>
                            {cle[0].toUpperCase() + cle.slice(1)} :
                          </td>
                          <td>
                            {" "}
                            {x.composition[cle as keyof typeof x.composition]}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <span>**valeur par 100g</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div
        className="w-screen flex justify-around"
        style={{ position: "fixed", bottom: "70px" }}
      >
        <Button
          disabled={activeCategory === 0 ? true : false}
          variant="contained"
          onClick={() => {
            dispatch({
              type: "changeCategory",
              payload: Math.max(activeCategory - 1, 0),
            });
          }}
        >
          back
        </Button>

        {data?.plate_has_ing_cat.length === activeCategory + 1 ? (
          <IconButton
            sx={{
              height: "36px",
              width: "69px",
              backgroundColor: "green",
              borderRadius: "25px",
              "&:hover": { backgroundColor: "green" },
            }}
            onClick={() => {
              fetch("/api/domand", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  plate: [
                    {
                      plate_id: parseInt(id!, 10),
                      ings: (
                        Object.keys(
                          chosenIngredients
                        ) as unknown[] as (keyof typeof chosenIngredients)[]
                      )
                        .filter(
                          (key: keyof typeof chosenIngredients) =>
                            chosenIngredients[key] !== undefined
                        )
                        .map((key: keyof typeof chosenIngredients) => ({
                          order_ing_id: parseInt(key),
                          order_ing_amount: chosenIngredients[key] as number,
                        })),
                    },
                  ],
                }),
              }).then(() => {
                enqueueSnackbar("Added to cart", {
                  variant: "success",
                });
                navigate("/cart");
              });
            }}
          >
            <AddShoppingCartIcon />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            disabled={
              activeCategoryObject?.type === "obligatory" &&
              activeCategoryObject?.ing
                .map(({ id }) => id)
                .reduce(
                  (acc, cur) =>
                    acc +
                    (chosenIngredients[
                      cur as unknown as keyof typeof chosenIngredients
                    ] || 0),
                  0
                ) === 0
            }
            onClick={() => {
              dispatch({
                type: "changeCategory",
                payload: activeCategory + 1,
              });
            }}
          >
            next
          </Button>
        )}
      </div>
    </div>
  );
}

export default CustomiseSandwich;
