import React, { useContext, useRef } from "react";
import { AuthContext } from "../context/Auth";
import { Button, Paper, TextField, Typography } from "@mui/material";
import { api } from "../utils/fetch";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export default function Profile() {
  const { user, refetch } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const phoneRef = useRef<HTMLInputElement>(null);
  return (
    <div className="flex justify-center items-center w-screen h-screen">
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "24px 48px",
        }}
      >
        <Typography variant="h4">Profile</Typography>
        <Typography variant="h5">{user?.username}</Typography>
        <Typography variant="h6">{user?.email}</Typography>
        <TextField
          label="Phone"
          inputProps={{ ref: phoneRef }}
          value={user?.phone}
        />
        <Button
          variant="contained"
          color="success"
          sx={{ height: "40px", marginTop: 2 }}
          onClick={() =>
            api("/api/user/updateAccount", {
              method: "POST",
              body: JSON.stringify({
                phone: phoneRef.current?.value,
              }),
            }).then(() => {
              enqueueSnackbar("Profile updated", {
                variant: "success",
              });
              refetch().then(() => {
                navigate("/");
              });
            })
          }
        >
          Save
        </Button>
      </Paper>
    </div>
  );
}
