export const api = async (url: string, reqInit?: RequestInit) =>
  await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    ...reqInit,
  }).then((res) => {
    if ([200, 201].includes(res.status)) return res.json();
    throw new Error("fetch failed");
  });
