import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/navbar/Navbar";
import Header from "./components/Header";
import CustomiseSandwich from "./components/CustomiseSandwich.jsx/CustomiseSandwich";
import Cart from "./components/cart/Cart";
import Orders from "./components/orders/Orders";
import Profile from "./pages/Profile";
import Mainpage2 from "./components/mainpage2/Mainpage2";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Header />
        <Mainpage2 />
        <Navbar />
      </>
    ),
  },
  {
    path: "/dish/:id",
    element: (
      <>
        <Header />
        <CustomiseSandwich />
        <Navbar />
      </>
    ),
  },
  {
    path: "/cart",
    element: (
      <>
        <Header />
        <Cart />
        <Navbar />
      </>
    ),
  },
  {
    path: "/orders",
    element: (
      <>
        <Header />
        <Orders />
        <Navbar />
      </>
    ),
  },
  {
    path: "/profile",
    element: (
      <>
        <Header />
        <Profile />
        <Navbar />
      </>
    ),
  },
  {
    path: "/home/:id",
    element: (
      <>
        <Header />
        <Home />
        <Navbar />
      </>
    ),
  },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
