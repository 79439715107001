import React from "react";

import CallIcon from "@mui/icons-material/Call";
import InstagramIcon from "@mui/icons-material/Instagram";
export default function Header() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {" "}
      <a href="tel:0662473328">
        {" "}
        <CallIcon sx={{ color: "blue" }} />
      </a>
      <img src="./static/img/logo2.png" alt="" style={{ height: "60px" }} />
      <a
        href="instagram:https://www.instagram.com/tebnej.box/"
        style={{
          background: "linear-gradient(#f9ce34, #ee2a7b, #6228d7)",
          color: "white",
        }}
        onClick={() =>
          setTimeout(
            () =>
              (window.location.href = "https://www.instagram.com/tebnej.box/"),
            1000
          )
        }
      >
        {" "}
        <InstagramIcon />
      </a>{" "}
    </div>
  );
}
