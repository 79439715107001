interface State {
  chosenIngredients: Record<string, number>;
  activeCategory: number;
  selectedIngridient: number | undefined;
}

export const initialState = {
  chosenIngredients: {},
  activeCategory: 0,
  error: "",
  selectedIngridient: undefined,
};

export type ReducerType =
  | "changeCategory"
  | "setIngredientQuantity"
  | "setSingleIngredient"
  | "selectIngridient"
  | "setIngredientQuantities";

export default function reducer(
  state: State,
  {
    payload,
    type,
  }: {
    payload: any;
    type: ReducerType;
  }
): State {
  switch (type) {
    case "changeCategory":
      return { ...state, activeCategory: payload };
    case "setIngredientQuantity":
      return {
        ...state,
        chosenIngredients: {
          ...state.chosenIngredients,
          [payload.id]: payload.quantity === 0 ? undefined : payload.quantity,
        },
      };
    case "setIngredientQuantities":
      return {
        ...state,
        chosenIngredients: { ...state.chosenIngredients, ...payload },
      };
    case "setSingleIngredient":
      return {
        ...state,
        chosenIngredients: {
          ...state.chosenIngredients,
          [payload.id]: 1,
          ...payload.init.reduce(
            (acc: Record<string, number | undefined>, cur: number) => ({
              ...acc,
              [cur]: undefined,
            }),
            {}
          ),
        },
      };
    case "selectIngridient":
      return {
        ...state,
        selectedIngridient: payload,
      };

    default:
      return { ...state, [type]: payload };
  }
}
