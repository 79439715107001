import React, { createContext, useContext, useEffect, useState } from "react";
import { googleLogout, useGoogleOneTapLogin } from "@react-oauth/google";
import { api } from "../utils/fetch";
interface IUser {
  username: string;
  email: string;
  phone?: string | null;
}

interface IAuth {
  user: IUser | null;
  isLogged: () => boolean;
  logout: () => void;
  refetch: () => Promise<void>;
}

export const AuthContext = createContext<IAuth>({
  user: null,
  isLogged: () => false,
  logout: () => {},
  refetch: () => Promise.resolve(),
});

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<IUser | null>(null);
  useGoogleOneTapLogin({
    onSuccess: (response) => {
      api("/api/auth/login", {
        method: "POST",
        body: JSON.stringify({
          type: "google",
          token: response.credential,
        }),
      }).then(() => {
        api("/api/user/me").then(
          (data: { username: string; email: string; phone: string | null }) => {
            setUser(data);
          }
        );
      });
    },
    onError: () => {
      console.log("error");
    },
  });

  function isLogged() {
    return user !== null;
  }
  function refetch() {
    return api("/api/user/me")
      .then(
        (data: { username: string; email: string; phone: string | null }) => {
          setUser(data);
        }
      )
      .catch(() => {});
  }

  useEffect(() => {
    refetch().catch(() => {});
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLogged,
        logout: googleLogout,
        refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
