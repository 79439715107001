import React from "react";
import "./mainpage2.css";
import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../utils/fetch";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import InstagramIcon from "@mui/icons-material/Instagram";
function Mainpage2() {
  const navigate = useNavigate();
  interface ICategory {
    id: number;
    name: string;
    icon: string;
  }
  const { data } = useQuery<ICategory[]>({
    queryKey: ["category"],
    queryFn: () => api("/api/plate-category"),
  });
  return (
    <>
      <div className="container">
        <div className="bodymain">
          <div className="body1">
            <div
              className="div1"
              onClick={() => {
                navigate("/home/1");
              }}
            >
              <img
                src={
                  data?.[0].icon
                    ? `/upload/${data?.[0].icon}`
                    : "/static/img/placeholder.png"
                }
                alt=""
                style={{
                  height: "130px",
                  width: "100%",
                }}
              />
              <span className="titre">Nos Plats</span>
            </div>
            <div
              className="div1"
              onClick={() => {
                navigate("/home/2");
              }}
            >
              <img
                src={
                  data?.[1].icon
                    ? `/upload/${data?.[1].icon}`
                    : "/static/img/placeholder.png"
                }
                alt=""
                style={{
                  height: "130px",
                  width: "100%",
                }}
              />
              <span className="titre">Nos Sandwich</span>
            </div>
            <div
              className="div1"
              onClick={() => {
                navigate("/dish/1002");
              }}
            >
              <img
                src={
                  data?.[2].icon
                    ? `/upload/${data?.[2].icon}`
                    : "/static/img/placeholder.png"
                }
                alt=""
                style={{
                  height: "130px",
                  width: "100%",
                }}
              />
              <span className="titre">Boissons</span>
            </div>
            <div
              className="div1"
              onClick={() => {
                navigate("/dish/1003");
              }}
            >
              <img
                src={
                  data?.[3].icon
                    ? `/upload/${data?.[3].icon}`
                    : "/static/img/placeholder.png"
                }
                alt=""
                style={{
                  width: "100%",
                  height: "130px",
                }}
              />
              <span className="titre">Desserts</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mainpage2;
