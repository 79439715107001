import React, { useContext, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { IconButton } from "@mui/material";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { List } from "@mui/icons-material";
import { AuthContext } from "../../context/Auth";
import "./navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();
  useEffect(() => {
    if (user !== null && user.phone === null) navigate("/profile");
  }, [pathname]);
  return (
    <>
      {" "}
      <AppBar
        position="fixed"
        sx={{
          top: "auto",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Toolbar sx={{ height: "fit-content" }}>
          <IconButton
            onClick={() => {
              navigate("/");
            }}
            sx={{ width: "20%", height: "30px" }}
          >
            <HomeOutlinedIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              navigate("/cart");
            }}
            sx={{ width: "20%", height: "30px" }}
          >
            <ShoppingCartOutlinedIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <div
            style={{
              width: "20%",
              backgroundColor: "transparent",
            }}
          >
            {" "}
          </div>
          <IconButton
            onClick={() => {
              navigate("/orders");
            }}
            sx={{ width: "20%", height: "30px" }}
          >
            <List sx={{ fontSize: "30px" }} />
          </IconButton>
          <IconButton
            sx={{ width: "20%", height: "30px" }}
            disabled={user === null}
            onClick={() => {
              navigate("/profile");
            }}
          >
            <Person2OutlinedIcon sx={{ fontSize: "30px" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div
        className="navbar_img"
        style={{
          height: "80px",
          position: "fixed",
          bottom: 0,
          left: "40%",
          borderRadius: "50%",
          zIndex: "1200",
          backgroundImage: 'url(" ./static/img/logo.png")',
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </>
  );
}

export default Navbar;
