import {
  Button,
  Collapse,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { api } from "../../utils/fetch";
import { IIngredient } from "../../pages/Home";
import { Add, ExpandLess, ExpandMore } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Order from "../order/order";

const stateMapper = {
  pending: "En attente",
  draft: "En cours",
  done: "Terminée",
};

export interface IOrder {
  id: number;
  create_time: string;
  state: keyof typeof stateMapper;
  price: {
    totale: number;
    order: {
      orderId: number;
      price: number;
      plate: {
        ing: IIngredient[];
        plateName: string;
        plateId: number;
      };
    }[];
  };
}

function Orders() {
  const { data: orders, refetch } = useQuery<IOrder[]>({
    queryKey: ["orders"],
    queryFn: () => api("/api/domand/user"),
  });
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [pickuptime, setpickuptime] = useState(new Date());

  const showDetails = (index: any) => {
    if (index === dropdown) {
      setdropdown(undefined);
    } else {
      setdropdown(index);
    }
  };
  console.log(orders);

  // const deleleItem = (ID) => {
  //   const buff = imgList.filter((x) => x.id !== ID);
  //   console.log(buff);
  //   setimgList(buff);
  // };
  const [dropdown, setdropdown] = useState();
  return (
    <div
      className="flex flex-col justify-between"
      style={{ height: "calc(100vh - 140px)" }}
    >
      <div className="flex flex-wrap flex-col items-center w-screen">
        <List sx={{ width: "100%", backgroundColor: "white", marginTop: 2 }}>
          {orders?.map(
            ({ price: { order, totale }, create_time, id, state }, index) => (
              <>
                <ListItemButton onClick={() => showDetails(index)}>
                  <ListItemText
                    primary={`${new Date(create_time).toLocaleString(
                      "fr-FR"
                    )} - ${totale} DA`}
                    secondary={stateMapper[state]}
                  />
                  <ListItemSecondaryAction>
                    {dropdown === index ? <ExpandLess /> : <ExpandMore />}
                  </ListItemSecondaryAction>
                </ListItemButton>

                <Collapse in={dropdown === index} timeout="auto" unmountOnExit>
                  <Order
                    order={order}
                    id={id}
                    refetch={refetch}
                    deletable={false}
                  />
                </Collapse>
              </>
            )
          )}
        </List>
      </div>
    </div>
  );
}

export default Orders;
