import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { SnackbarProvider } from "notistack";
import Router from "./router";
import AuthProvider from "./context/Auth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import OpenContextProvider from "./context/Open";
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId="258159391366-s21cvt6d7p2pjqvj4f0a9ql5iklkia49.apps.googleusercontent.com">
        <SnackbarProvider
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <OpenContextProvider>
            <AuthProvider>
              <Router />
            </AuthProvider>
          </OpenContextProvider>
        </SnackbarProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}

export default App;
